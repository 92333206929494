.contrastCard{
    filter: contrast(0.98);
}
.contrastImg {
    background-color: white;
    filter: contrast(0.950);
    transition: filter 0.1s, border 0.1s;
}

.contrastImg:hover {
    filter: contrast(1);
}

/* Cuando se hace hover en .contrastCard, .contrastImg debe tener contrast 1 */

.contrastCard:hover{
    filter: contrast(1);
}
.contrastCard:hover .contrastParent .contrastImg {
    filter: contrast(1);
}